import { Container } from '@/shared/ui/container';
import { Logo } from '@/shared/ui/logo';
import styles from './styles.module.scss';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.root}>
      <Container className={styles.container}>
        <Logo className={styles.logo} />
        <span className={styles.copy}>© {currentYear}</span>
      </Container>
    </footer>
  );
};
