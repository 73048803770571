import type { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

type ButtonVariant = 'primary' | 'outlined';

interface ButtonProps extends PropsWithChildren {
  as?: 'button' | 'a';
  href?: string;
  download?: boolean;
  target?: string;
  variant?: ButtonVariant;
  className?: string;
  hidden?: boolean;
  onClick?: () => void;
}

export const Button = ({
  className,
  as = 'button',
  children,
  variant = 'primary',
  ...rest
}: ButtonProps) => {
  const Element = as;

  return (
    <Element className={clsx(styles.btn, styles[variant], className)} {...rest}>
      <span className={styles.content}>{children}</span>
    </Element>
  );
};
