import { Section } from '@/shared/ui/section';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import { benefitsItems } from './config/benefits.config';
import styles from './styles.module.scss';
import { ParallaxImage } from '@/shared/ui/parallax-image';

export const Benefits = () => {
  const { t } = useTranslation();

  const items = benefitsItems(t);

  return (
    <Section className={styles.section}>
      <Container>
        <Typography className={styles.title} tag="h2">
          {t('benefits.title')}
        </Typography>
        <ul className={styles.list}>
          {items.map((item) => {
            const Icon = item.icon;

            return (
              <li className={styles.item} key={item.label}>
                <Icon className={styles.icon} />
                <span className={styles.label}>{item.label}</span>
              </li>
            );
          })}
        </ul>
        <ParallaxImage className={styles.coin} src="coins/1" />
        <ParallaxImage className={styles.diamond} src="diamonds/1" />
      </Container>
    </Section>
  );
};
