import { ReactComponent as LikeIcon } from '../assets/like.svg';
import { ReactComponent as TimerIcon } from '../assets/timer.svg';
import { ReactComponent as BagTickIcon } from '../assets/bag-tick.svg';
import { ReactComponent as ActivityIcon } from '../assets/activity.svg';
import { ReactComponent as EyeIcon } from '../assets/eye.svg';
import { ReactComponent as LockIcon } from '../assets/lock.svg';
import type { TFunction } from 'i18next';

export const offerItems = (t: TFunction) => [
  {
    Icon: LikeIcon,
    label: t('offer.text1'),
  },
  {
    Icon: TimerIcon,
    label: t('offer.text2'),
  },
  {
    Icon: BagTickIcon,
    label: t('offer.text3'),
  },
  {
    Icon: ActivityIcon,
    label: t('offer.text4'),
  },
  {
    Icon: LockIcon,
    label: t('offer.text5'),
  },
  {
    Icon: EyeIcon,
    label: t('offer.text6'),
  },
];
