import type { SVGProps } from 'react';
import type { SpritesMap } from './sprite.h';

export type IconName = {
  [Key in keyof SpritesMap]: `${Key}/${SpritesMap[Key]}`;
}[keyof SpritesMap];

export interface IconProps
  extends Omit<SVGProps<SVGSVGElement>, 'name' | 'type'> {
  name: IconName;
}

export const Icon = ({ name, viewBox, ...rest }: IconProps) => {
  const [spriteName, iconName] = name.split('/');

  return (
    <svg viewBox={viewBox} focusable="false" aria-hidden {...rest}>
      <use xlinkHref={`/sprites/${spriteName}.svg#${iconName}`} />
    </svg>
  );
};
