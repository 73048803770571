import { useTranslation } from 'react-i18next';
import { Container } from '@/shared/ui/container';
import { ReactComponent as BannerImage } from './assets/banner.svg';
import { Typography } from '@/shared/ui/typography';
import { ToggleSocial } from '@/features/toggle-social';
import styles from './styles.module.scss';

export const Hero = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.content}>
          <Typography className={styles.title} tag="h1">
            {t('hero.title')}
          </Typography>
          <p className={styles.desc}>{t('hero.description')}</p>
          <ToggleSocial
            rowClassName={styles.toggleRow}
            className={styles.btn}
          />
        </div>
      </Container>
      <BannerImage className={styles.banner} />
    </section>
  );
};
