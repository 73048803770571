import { Icon } from '@/shared/ui/icon';
import type { BaseWorkItem } from '../../config/way-working-items.config';
import styles from './styles.module.scss';
import { useElementOnScreen } from '@/shared/lib/use-element-on-screen.lib';
import clsx from 'clsx';

interface WorkItemProps {
  item: BaseWorkItem;
}

export const WorkItem = ({ item }: WorkItemProps) => {
  const [itemRef, isVisible] = useElementOnScreen<HTMLLIElement>({
    threshold: 1,
  });

  return (
    <li
      ref={itemRef}
      className={clsx(styles.item, { [styles.isVisible]: isVisible })}
      key={item.description}
    >
      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          src={item.image}
          loading="lazy"
          alt={item.description}
        />
        <Icon className={styles.puddle} name="puddles/1" />
      </div>
      <span
        className={styles.label}
        dangerouslySetInnerHTML={{ __html: item.description }}
      />
    </li>
  );
};
