import clsx from 'clsx';
import type { HTMLProps } from 'react';
import styles from './styles.module.scss';

type TypographyTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p';

interface TypographyProps extends HTMLProps<HTMLHeadingElement> {
  tag: TypographyTag;
  textStyle?: TypographyTag;
}

export const Typography = ({
  tag = 'h2',
  className,
  textStyle,
  children,
  ...rest
}: TypographyProps) => {
  const Element = tag;

  return (
    <Element
      className={clsx(styles.typography, styles[textStyle ?? tag], className)}
      {...rest}
    >
      {children}
    </Element>
  );
};
