import { Section } from '@/shared/ui/section';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CertifiedIcon } from './assets/certified.svg';
import { offerItems } from './config/offer.config';
import { offerBenefitsItems } from './config/benefits.config';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import { ToggleSocial } from '@/features/toggle-social';
import styles from './styles.module.scss';

export const Offer = () => {
  const { t } = useTranslation();

  const items = offerItems(t);

  const benefitsItems = offerBenefitsItems(t);

  return (
    <Section className={styles.section}>
      <Container className={styles.container}>
        <Typography className={styles.title} tag="h2">
          {t('offer.title')}
        </Typography>
        <ul className={styles.list}>
          {items.map(({ Icon, label }) => (
            <li className={styles.item} key={label}>
              <div className={styles.icon}>
                <Icon />
              </div>
              <span className={styles.label}>{label}</span>
            </li>
          ))}
        </ul>
        <div className={styles.certified}>
          <CertifiedIcon className={styles.certifiedIcon} />
          <span className={styles.certifiedLabel}>
            {t('licensed_software')}
          </span>
        </div>
      </Container>
      <ul className={styles.benefits}>
        {benefitsItems.map((item) => (
          <li className={styles.benefitsItem} key={item.quantity}>
            <Typography
              textStyle="h2"
              tag="span"
              className={styles.benefitsQuantity}
            >
              {item.quantity}
            </Typography>
            <span className={styles.benefitsLabel}>{item.label}</span>
          </li>
        ))}
      </ul>
      <ToggleSocial rowClassName={styles.toggleRow} className={styles.btn} />
      <ParallaxImage src="coins/1" className={styles.coin} />
    </Section>
  );
};
