import styles from './styles.module.scss';
import clsx from 'clsx';
import { Typography } from '@/shared/ui/typography';
import { type CSSProperties } from 'react';
import { useElementOnScreen } from '@/shared/lib/use-element-on-screen.lib';
import type { BaseGiftItem } from '../../config/gifts-items.config';
import type { IconName } from '@/shared/ui/icon';
import { Icon } from '@/shared/ui/icon';
import { ToggleSocial } from '@/features/toggle-social';

interface GiftItemProps {
  item: BaseGiftItem;
}

export const GiftItem = ({ item }: GiftItemProps) => {
  const [itemRef, isVisible] = useElementOnScreen({
    threshold: 0.1,
  });

  return (
    <div
      ref={itemRef}
      style={{ '--flex-direction': item.flexDirection } as CSSProperties}
      className={clsx(styles.item, styles[`item${item.index}`], {
        [styles.isVisible]: isVisible,
      })}
    >
      <div className={styles.text}>
        <Typography
          tag="h2"
          className={clsx(
            styles.title,
            item.isLastElement ? styles.yellowTitle : null,
          )}
          dangerouslySetInnerHTML={{ __html: item.title }}
        />
        <Typography
          tag="p"
          className={styles.desc}
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
        {item.isLastElement ? (
          <ToggleSocial
            rowClassName={styles.toggleRow}
            buttonText="start_now"
            className={styles.btn}
          />
        ) : null}
      </div>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={item.image} alt={item.title} />
        <Icon
          width={394}
          height={440}
          name={item.puddleImage as IconName}
          className={styles.puddleImage}
        />
      </div>
    </div>
  );
};
