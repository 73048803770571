import { useState, type HTMLAttributes } from 'react';
import { Button } from '@/shared/ui/button';
import { useTranslation } from 'react-i18next';
import { Social } from '@/shared/ui/social';
import { Icon } from '@/shared/ui/icon';
import styles from './styles.module.scss';
import clsx from 'clsx';

type ButtonText = 'contact_us' | 'start_now' | 'subscribe_for_updates';

type ToggleSocialProps = HTMLAttributes<HTMLDivElement> & {
  rowClassName?: string;
  buttonText?: ButtonText;
};

export const ToggleSocial = ({
  rowClassName,
  buttonText = 'contact_us',
  ...rest
}: ToggleSocialProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <div hidden={!isOpened} className={clsx(styles.row, rowClassName)}>
        <Social />
        <button className={styles.close} onClick={() => setIsOpened(false)}>
          <Icon name="common/close" />
        </button>
      </div>
      <Button {...rest} hidden={isOpened} onClick={() => setIsOpened(true)}>
        {t(buttonText)}
      </Button>
    </>
  );
};
