import type { IconName } from '../../icon';

interface SocialItem {
  href: string;
  icon: IconName;
}

export const socialItems: SocialItem[] = [
  {
    href: 'skype:live:.cid.b58c469d1a483e36?chat',
    icon: 'social/skype',
  },
  {
    href: 'https://t.me/Bet_ibet',
    icon: 'social/telegram',
  },
  {
    href: 'https://wa.me/380955781044',
    icon: 'social/whatsapp',
  },
  {
    href: 'mailto:office@ib-games.com',
    icon: 'social/email',
  },
];
