export const ourGamesItems = [
  {
    href: '#',
    logo: {
      url: `/images/our-games/logos/1.png`,
      width: 154.29,
      height: 54,
    },
    previewUrl: `/images/our-games/previews/1.png`,
    alt: 'Pilot',
  },
  {
    href: '#',
    logo: {
      url: `/images/our-games/logos/2.png`,
      width: 175,
      height: 54,
    },
    previewUrl: `/images/our-games/previews/2.png`,
    alt: 'Fortuna',
  },
  {
    href: '#',
    logo: {
      url: `/images/our-games/logos/3.png`,
      width: 145,
      height: 54,
    },
    previewUrl: `/images/our-games/previews/3.png`,
    alt: 'CountryPilot',
  },
];
