import clsx from 'clsx';
import { Icon } from '@/shared/ui/icon';
import { menuItems } from './config/menu-items.config';
import { useTranslation } from 'react-i18next';
import { useEscape } from './lib/use-escape.lib';
import { useLockedBody } from './lib/use-locked-body.lib';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import { useWindowSize } from './lib/use-window-size.lib';
import type { CSSProperties } from 'react';
import styles from './styles.module.scss';

interface MenuProps {
  isOpened: boolean;
  close: () => void;
}

const countOfItems = 9;

export const menuDecorItems = Array.from({ length: countOfItems }).map(
  (_, idx) => idx + 1,
);

export const Menu = ({ isOpened, close }: MenuProps) => {
  const { t } = useTranslation();

  const windowSize = useWindowSize();

  useLockedBody(isOpened);

  useEscape(close);

  const items = menuItems(t);

  return (
    <div
      style={{ '--vh': `${windowSize.height}px` } as CSSProperties}
      className={clsx(styles.menu, { [styles.isOpened]: isOpened })}
    >
      <button onClick={close} className={styles.close}>
        <Icon name="common/close" />
      </button>
      <ul className={styles.list}>
        {items.map((item) => (
          <li key={item.text} className={styles.item}>
            <a onClick={close} className={styles.link} href={item.href}>
              {item.text}
            </a>
          </li>
        ))}
      </ul>
      {menuDecorItems.map((index) => (
        <ParallaxImage
          key={index}
          className={styles[`decor${index}`]}
          src={`menu/${index}`}
        />
      ))}
    </div>
  );
};
