import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { locales } from './i18n-locales';
import { DEFAULT_LANGUAGE, LS_LANG_KEY } from './consts';

const i18nInstance = use(initReactI18next);

i18nInstance.init({
  resources: locales,
  lng: localStorage.getItem(LS_LANG_KEY) || DEFAULT_LANGUAGE,
  fallbackLng: Object.keys(locales),
});
