import type { TFunction } from 'i18next';

const countOfItems = 3;

export const giftsItems = (t: TFunction) =>
  Array.from({ length: countOfItems })
    .map((_, idx) => idx + 1)
    .map((index) => ({
      index,
      image: `/images/gifts/${index}.png`,
      puddleImage: `puddles/${index === 2 ? '3' : '2'}`,
      title: t(`gifts.item_${index}.title`),
      description: t(`gifts.item_${index}.description`),
      flexDirection: index === 2 ? 'row-reverse' : 'row',
      isLastElement: index === countOfItems,
    }));

export type BaseGiftItem = ReturnType<typeof giftsItems>[number];
