/* eslint-disable jsx-a11y/media-has-caption */
import { Section } from '@/shared/ui/section';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import { useTranslation } from 'react-i18next';
// import { Button } from '@/shared/ui/button';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import styles from './styles.module.scss';
import { ToggleSocial } from '@/features/toggle-social';

export const HowItWorks = () => {
  const { t } = useTranslation();

  return (
    <Section id="how-it-works" className={styles.section}>
      <Container className={styles.container}>
        <Typography className={styles.title} tag="h2">
          {t('how_it_works.title')}
        </Typography>
        <div className={styles.videoWrapper}>
          <video controls className={styles.video} playsInline>
            <source src="/PILOT-TVFOR-LENDOS.mp4" type="video/mp4" />
          </video>
        </div>
        <div className={styles.btns}>
          {/* <Button
            as="a"
            href="#"
            target="_blank"
            variant="outlined"
            className={styles.btn}
          >
            {t('go_to_site')}
          </Button> */}
          <ToggleSocial
            rowClassName={styles.toggleRow}
            className={styles.btn}
          />
        </div>
      </Container>
      <ParallaxImage src="diamonds/2" className={styles.diamond} />
    </Section>
  );
};
