import { Section } from '@/shared/ui/section';
import { Container } from '@/shared/ui/container';
import { useTranslation } from 'react-i18next';
import { GiftItem } from './ui/gift-item';
import { giftsItems } from './config/gifts-items.config';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import styles from './styles.module.scss';

export const Gifts = () => {
  const { t } = useTranslation();

  const items = giftsItems(t);

  return (
    <Section className={styles.section} id="marketing-tools">
      <Container className={styles.container}>
        {items.map((item) => (
          <GiftItem key={item.index} item={item} />
        ))}
      </Container>
      <ParallaxImage className={styles.coin1} src="coins/1" />
      <ParallaxImage className={styles.coin2} src="coins/1" />
      <ParallaxImage className={styles.diamond} src="diamonds/3" />
    </Section>
  );
};
