import { ReactComponent as WalletAddIcon } from '../assets/benefits/wallet-add.svg';
import { ReactComponent as FlashIcon } from '../assets/benefits/flash.svg';
import { ReactComponent as CrownIcon } from '../assets/benefits/crown.svg';
import { ReactComponent as RankingIcon } from '../assets/benefits/ranking.svg';
import type { TFunction } from 'i18next';

export const benefitsItems = (t: TFunction) => [
  {
    icon: WalletAddIcon,
    label: t('benefits.text1'),
  },
  {
    icon: FlashIcon,
    label: t('benefits.text2'),
  },
  {
    icon: CrownIcon,
    label: t('benefits.text3'),
  },
  {
    icon: RankingIcon,
    label: t('benefits.text4'),
  },
];
