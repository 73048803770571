import { useTranslation } from 'react-i18next';
import { Container } from '@/shared/ui/container';
import { Section } from '@/shared/ui/section';
import { Typography } from '@/shared/ui/typography';
import { Button } from '@/shared/ui/button';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import { ourGamesItems } from './config/our-games.config';
import styles from './styles.module.scss';
import { ToggleSocial } from '@/features/toggle-social';

export const OurGames = () => {
  const { t, i18n: { language } } = useTranslation();

  return (
    <Section id="all-games" className={styles.section}>
      <Container className={styles.container}>
        <Typography className={styles.title} tag="h2">
          {t('our_games.title')}
        </Typography>
        <ul className={styles.list}>
          {ourGamesItems.map((item) => (
            <li className={styles.item} key={item.alt}>
              <a href={item.href} className={styles.link}>
                <img
                  width={item.logo.width}
                  height={item.logo.height}
                  className={styles.logo}
                  src={item.logo.url}
                  alt={item.alt}
                />
                <div className={styles.imageWrapper}>
                  <img
                    className={styles.image}
                    src={item.previewUrl}
                    alt={item.alt}
                  />
                </div>
              </a>
            </li>
          ))}
          <li className={styles.item}>
            <div className={styles.itemContent}>
              <h3 className={styles.itemTitle}>{t('coming_soon')}</h3>
              <ToggleSocial
                rowClassName={styles.toggleRow}
                className={styles.itemBtn}
                buttonText="subscribe_for_updates"
              />
            </div>
          </li>
        </ul>
        <Button as='a' href={`/presentations/${language}.pdf`} download className={styles.btn}>{t('download_presentation')}</Button>
        <ParallaxImage src="coins/1" className={styles.coin} />
      </Container>
    </Section>
  );
};
