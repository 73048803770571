import { useState } from 'react';
import { LangPicker } from '@/shared/ui/lang-picker';
import { Container } from '@/shared/ui/container';
import { Logo } from '@/shared/ui/logo';
import { Icon } from '@/shared/ui/icon';
import { Menu } from './ui/menu';
import styles from './styles.module.scss';

export const Header = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  return (
    <header className={styles.root}>
      <Container className={styles.container}>
        <Logo />
        <div className={styles.right}>
          <LangPicker />
          <button onClick={() => setIsMenuOpened(true)} className={styles.menu}>
            <Icon name="common/category" />
          </button>
        </div>
      </Container>
      <Menu isOpened={isMenuOpened} close={() => setIsMenuOpened(false)} />
    </header>
  );
};
