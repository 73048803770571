import type { TFunction } from 'i18next';

export const menuItems = (t: TFunction) => [
  {
    href: '#how-it-works',
    text: t('menu.how_it_works'),
  },
  {
    href: '#marketing-tools',
    text: t('menu.marketing_tools'),
  },
  {
    href: '#all-games',
    text: t('menu.all_games'),
  },
];
