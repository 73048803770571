import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ScrollProvider } from '@/shared/context/scroll-context';
import { Header } from '@/widgets/header';
import { Hero } from '@/widgets/hero';
import { Benefits } from '@/widgets/benefits';
import { Offer } from '@/widgets/offer';
import { WayWorking } from '@/widgets/way-working';
import { HowItWorks } from '@/widgets/how-it-works';
import { Gifts } from '@/widgets/gifts';
import { OurGames } from '@/widgets/our-games';
import { Footer } from '@/widgets/footer';

export const App = () => {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <ScrollProvider>
        <Helmet>
          <title>{t('meta.title')}</title>
          <meta name="description" content={t('meta.description')} />
        </Helmet>
        <Header />
        <main className="main">
          <Hero />
          <Benefits />
          <Offer />
          <WayWorking />
          <HowItWorks />
          <Gifts />
          <OurGames />
        </main>
        <Footer />
      </ScrollProvider>
    </HelmetProvider>
  );
};
