import type { HTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

type ContainerProps = HTMLAttributes<HTMLDivElement>;

export const Container = ({ className, children, ...rest }: ContainerProps) => {
  return (
    <div className={clsx(styles.container, className)} {...rest}>
      {children}
    </div>
  );
};
