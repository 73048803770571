import type { TFunction } from 'i18next';

const countOfItems = 4;

export const wayWorkingItems = (t: TFunction) =>
  Array.from({ length: countOfItems })
    .map((_, idx) => idx + 1)
    .map((index) => ({
      image: `/images/way-working/${index}.png`,
      description: t(`way_of_working.text${index}`),
    }));

export type BaseWorkItem = ReturnType<typeof wayWorkingItems>[number];
