import type { TFunction } from 'i18next';

export const offerBenefitsItems = (t: TFunction) => [
  {
    quantity: '15',
    label: t('offer.benefits.text1'),
  },
  {
    quantity: '2 354',
    label: t('offer.benefits.text2'),
  },
  {
    quantity: '9 665 945',
    label: t('offer.benefits.text3'),
  },
];
