import type { PropsWithChildren } from 'react';
import { createContext, useEffect, useState } from 'react';

const initialValue = { scrollY: 0 };

export const ScrollContext = createContext<typeof initialValue>(initialValue);

export const ScrollProvider = ({ children }: PropsWithChildren) => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ScrollContext.Provider value={{ scrollY }}>
      {children}
    </ScrollContext.Provider>
  );
};
