import type { HTMLAttributes } from 'react';
import { Icon } from '../icon';
import { socialItems } from './config/social-items.config';
import styles from './styles.module.scss';
import clsx from 'clsx';

type SocialProps = HTMLAttributes<HTMLUListElement>;

export const Social = ({ className }: SocialProps) => {
  return (
    <ul className={clsx(styles.list, className)}>
      {socialItems.map((item) => (
        <li key={item.icon} className={styles.item}>
          <a
            className={styles.link}
            href={item.href}
            target="_blank"
            rel="noopener"
          >
            <Icon name={item.icon} />
          </a>
        </li>
      ))}
    </ul>
  );
};
