import type { HTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

type SectionProps = HTMLAttributes<HTMLElement>;

export const Section = ({ className, children, ...rest }: SectionProps) => {
  return (
    <section className={clsx(styles.root, className)} {...rest}>
      {children}
    </section>
  );
};
